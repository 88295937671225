import styled, { css } from "styled-components";

import Backgrounds from "../../../../../assets/backgrounds/index";

export const AcquireEquipmentContainer = styled.div(
  ({ theme: { downTo } }) => css`
    position: relative;
    background-image: url(${Backgrounds.BenefitsDesktop});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 1110px;
    padding: 1rem;

    ${downTo("md")} {
      padding-top: 200px;
      height: 1700px;
    }

    ${downTo("sm")} {
      padding-top: 0px;
      background-image: url(${Backgrounds.BenefitsMobile});
      height: 1535px;
    }
  `
);

export const GridContainer = styled.div(
  ({ theme: { downTo } }) => css`
    padding-top: 200px;
    ${downTo("md")} {
      padding-top: 50px;
    }
  `
);

export const TextContainer = styled.div(
  ({ theme: { downTo }, textWidth = "240px" }) => css`
    justify-content: center;
    align-items: left;
    text-align: center;
    width: ${textWidth};

    ${downTo("lg")} {
      width: 70%;
    }

    ${downTo("md")} {
      padding-top: 20px;
      width: ${textWidth};
    }
  `
);

export const Image = styled.img(
  ({ theme: { downTo } }) => css`
    max-width: 100%;
    max-height: 260px;

    ${downTo("xl")} {
      max-height: 200px;
      max-width: 250px;
    }

    ${downTo("md")} {
      height: 120px;
    }
  `
);
