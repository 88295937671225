import styled, { css } from "styled-components";

import Backgrounds from "../../../../../assets/backgrounds/index";
import { Heading1 } from "../../../../../components/text/Heading";

export const AcquireEquipmentContainer = styled.div(
  ({ theme: { downTo } }) => css`
    position: relative;
    background-image: url(${Backgrounds.AcquireEquipmentDesktop});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 60vh;
    min-height: 600px;
    margin-bottom: 5%;

    ${downTo("md")} {
      background-image: url(${Backgrounds.AcquireEquipmentMobile});
      min-height: 400px;
      margin-bottom: 15%;
    }
  `
);

export const Paragraph = styled(Heading1)(
  ({ theme: { downTo, colors } }) => css`
    font-size: 40px;
    line-height: 50px;
    color: ${colors.neutral00};
    font-family: "InterSemiBold";

    ${downTo("md")} {
      font-size: 24px;
      line-height: 28px;
      font-family: "InterBold";
    }

    ${downTo("sm")} {
      font-size: 20px;
    }
  `
);

export const TextContainer = styled.div(
  ({ theme: { downTo } }) => css`
    width: 500px;
    position: absolute;
    left: 9%;
    top: 10%;

    ${downTo("md")} {
      width: 350px;
      left: 7.5%;
    }

    ${downTo("sm")} {
      width: 250px;
    }
  `
);

export const Image = styled.img(
  ({ theme: { downTo } }) => css`
    width: 50vw;
    max-width: 790px;
    display: block;

    ${downTo("md")} {
      height: auto;
      width: 90vw;
      max-height: 40vh;
    }
  `
);

export const ImageContainer = styled.div(
  ({ theme: { downTo } }) => css`
    position: absolute;
    bottom: 7%;

    ${downTo("md")} {
      bottom: -15%;
    }
  `
);

export const ButtonContainer = styled.div(
  ({ theme: { downTo } }) => css`
    position: absolute;
    top: 50%;
    width: 250px;
    left: 9%;

    ${downTo("md")} {
      top: 45%;
      left: 7.5%;
    }
  `
);
