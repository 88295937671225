import styled, { css } from "styled-components";
import { Paragraph1, Paragraph2 } from "../../text/Paragraph";

export const TitleEnd = styled(Paragraph2)(
  css`
    text-align: end;
  `
);
export const SubtitleEnd = styled(Paragraph1)(
  css`
    text-align: end;
  `
);

export const DescriptionWrapper = styled.div(
  ({ theme: { downTo } }) =>
    css`
      display: flex;
      flex-direction: column;
      max-width: 300px;
      min-width: 250px;
      margin-left: 1rem;
      align-items: baseline;
      ${downTo("lg")} {
        min-width: 140px;
        max-width: 220px;
      }
    `
);

export const DescriptionWrapperEnd = styled(DescriptionWrapper)(
  css`
    align-items: flex-end;
    margin-left: 0;
    margin-right: 1rem;
  `
);

export const MobileSubtitle = styled(Paragraph1)(
  ({ theme: { downTo, upTo } }) => css`
    font-size: 0.9rem;
    line-height: 1.2rem;
    ${upTo("xxs")} {
      font-size: 0.75rem;
      max-width: 140px;
    }
    ${downTo("xxs")} {
      font-size: 0.7rem;
      max-width: 120px;
    }
  `
);
