import styled, { css } from "styled-components";
import { ReactComponent as LogoIcon } from "../../assets/header/logo.svg";
import { motion } from "framer-motion";

export const NavbarContainer = styled.nav(
  ({ theme: { colors, shadows, navbar }, shadow, activeMenu }) => css`
    position: fixed;
    top: 0;
    width: 100%;
    height: 75px;
    padding: 0;
    box-shadow: ${shadow && shadows.soft};
    transition: box-shadow 1s ease-in-out;
    background: ${activeMenu ? colors.mainPrimary20 : navbar.background};
    display: inline-flex;
    z-index: 999;
  `
);

export const Content = styled.div`
  margin: 0.5rem 7.5%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StLogoIcon = styled(LogoIcon)(
  ({ theme: { navbar, colors, downTo }, activeMenu }) => css`
    height: 50%;
    ${downTo("md")} {
      margin-left: -3rem;
    }
    ${downTo("xs")} {
      margin-left: -2rem;
    }
    path {
      fill: ${activeMenu ? colors.neutral00 : navbar.logo};
    }
    :hover {
      cursor: pointer;
    }
  `
);

export const AnimationWrapper = styled(motion.div)(
  ({ visible }) => css`
    height: 75px;
    ${visible
      ? css`
          > div {
            visibility: visible;
          }
        `
      : css`
          > div {
            visibility: hidden;
          }
        `}
  `
);

export const NavBarButton = styled.img`
  height: 30%;
  display: block;
  cursor: pointer;
`;
