/*----- Core -----*/
import React from "react";
import { useTranslation } from "react-i18next";

import { Paragraph, Member } from "./utils";
import { Col, Row } from "../../../../../components/Grid";
import { Heading1 } from "../../../../../components/text/Heading";

import {
  WhoWeAreContainer,
  ParagraphContainer,
  Image,
  ImageContainer,
  TextContainer,
  StyledParagraph1,
} from "./styles";

const BenefitsSection = () => {
  const { t } = useTranslation("translation");

  const Paragraphs = () => {
    return Paragraph.map((paragraph) => {
      return <ParagraphContainer>{t(paragraph.text)}</ParagraphContainer>;
    });
  };

  const Members = () => {
    return Member.map((member) => {
      return (
        <Col width="32%" paddingVerticalMobile="5%">
          <ImageContainer>
            <TextContainer>
              <StyledParagraph1>{t(member.name)}</StyledParagraph1>
            </TextContainer>
            <Image src={member.image} alt={member.name} />
          </ImageContainer>
        </Col>
      );
    });
  };

  return (
    <WhoWeAreContainer id="AboutUs">
      <Row>
        <Col width="40%">
          <Heading1>{t("aboutUs.title")}</Heading1>
          <Paragraphs />
        </Col>
        <Col width="60%">
          <Row paddingHorizontal="2.5%">
            <Members />
          </Row>
        </Col>
      </Row>
    </WhoWeAreContainer>
  );
};

export default BenefitsSection;
