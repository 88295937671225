import styled, { css } from "styled-components";

export const FunctioningContainerMobile = styled.section(
  ({ theme: { downTo } }) => css`
    position: relative;
    min-height: 1550px;
    margin: 15% 5% 5% 5%;
    overflow-x: hidden;
    ${downTo('md')}{
      margin: 15% 2.75%;
    }
    ${downTo('xxs')}{
      margin: 15% 1.5% 0 1.5%;
    }
  `
);

export const FunctioningElementsMobile = styled.div`
  position: relative;
  margin: 2rem 0;
`;
