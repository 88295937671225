import {
  NavbarContainer,
  Content,
  StLogoIcon,
  AnimationWrapper,
} from "./styles";
import Menu from "./Menu";
import Burger from "./Burger";
import React, { useEffect, useState } from "react";
import { MenuContainer } from "./Menu/styles";
import { useLocation, useHistory } from "react-router-dom";

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [shadow, setShadow] = useState(false);
  const location = useLocation();
  const { push } = useHistory();

  const toggleMenu = () => {
    setActiveMenu((activeMenu) => !activeMenu);
  };

  const handleScroll = () => {
    if (window.scrollY > 75) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setActiveMenu(false);
  }, [location]);

  useEffect(() => {
    document.body.style.overflowY = activeMenu ? "hidden" : "scroll";
  }, [activeMenu]);

  return (
    <AnimationWrapper visible={activeMenu}>
      <NavbarContainer shadow={shadow} activeMenu={activeMenu}>
        <Content>
          <StLogoIcon onClick={() => push("/")} activeMenu={activeMenu} />
          <Burger toggle={toggleMenu} activeMenu={activeMenu} />
        </Content>
      </NavbarContainer>
      <MenuContainer id="Container">
        <Menu activeMenu={activeMenu} />
      </MenuContainer>
    </AnimationWrapper>
  );
};

export default Navbar;
