import React from "react";
import { useTranslation } from "react-i18next";

import {
  AcquireEquipmentContainer,
  GridContainer,
  TextContainer,
  Image,
} from "./styles";

import { Col, Row, SectionRow } from "../../../../../components/Grid";
import { Paragraph1 } from "../../../../../components/text/Paragraph";
import { Heading1 } from "../../../../../components/text/Heading";
import { Row1, Row2, Row3 } from "./utils";

const BenefitsSection = () => {
  const { t } = useTranslation("translation");

  const getColumns = (CurrentRow) => {
    return CurrentRow.map((col) => {
      return (
        <Col
          paddingVerticalMobile="40px"
          width={col.width}
          marginTopDesktop={col.marginTop}
          marginLeftDesktop={col.marginLeft}
        >
          <Image src={col.image} alt={t(col.altText)} />
          <TextContainer textWidth={col.textWidth}>
            <Paragraph1>{t(col.text)}</Paragraph1>
          </TextContainer>
        </Col>
      );
    });
  };

  return (
    <AcquireEquipmentContainer id="Benefits">
      <GridContainer>
        <SectionRow>
          <Col justify="top">
            <Heading1>{t("benefits.title")}</Heading1>
          </Col>
          {getColumns(Row1)}
        </SectionRow>

        <Row marginTopDesktop="-100px">{getColumns(Row2)}</Row>

        <Row marginTopDesktop="0px" paddingHorizontal="9%">
          {getColumns(Row3)}
        </Row>
      </GridContainer>
    </AcquireEquipmentContainer>
  );
};

export default BenefitsSection;
