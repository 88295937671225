/*----- Core -----*/
import React from "react";
import { useTranslation } from "react-i18next";

import { Col, Row } from "../../../../../components/Grid";
import EquipmentImages from "../../../../../assets/acquireEquipment/index";
import Button from "../../../../../components/button/DefaultButton";
import {
  AcquireEquipmentContainer,
  Paragraph,
  Image,
  TextContainer,
  ImageContainer,
  ButtonContainer,
} from "./styles";

const AcquireEquipment = () => {
  const { t } = useTranslation("translation");

  return (
    <AcquireEquipmentContainer>
      <Col>
        <TextContainer>
          <Paragraph>{t("header.heading")}</Paragraph>
        </TextContainer>
      </Col>

      <Row>
        <Col>
          <ButtonContainer>
            <Button
              destination="/acquire_equipment"
              content={t("header.button")}
            />
          </ButtonContainer>
        </Col>
        <Col>
          <ImageContainer>
            <Image src={EquipmentImages.Equipment} alt="Equipo de Culbot" />
          </ImageContainer>
        </Col>
      </Row>
    </AcquireEquipmentContainer>
  );
};

export default AcquireEquipment;
