export const fetchMail = async (body) => {
  const response = await fetch("https://mensajes.snappler.com/api/messages", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token token=${process.env.REACT_APP_API_MENSAJES_TOKEN}`,
    },
    mode: "cors",
    body: body,
  });
  return await response.json();
};

export const fetchCities = async (name = "") => {
  const response = await fetch(
    `https://apis.datos.gob.ar/georef/api/localidades?&campos=id,nombre,provincia&max=5&nombre=${name}`
  );
  const data = await response.json();
  if (response.status === 200) return data.localidades;
  else return [];
};
