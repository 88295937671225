import styled, { css } from "styled-components";

export const StyledLabel = styled.label(
  ({ theme: { colors, typography, downTo } }) =>
    css`
      width: auto;
      font-size: ${typography.sizes.large};
      font-family: "InterRegular";
      font-style: normal;
      color: ${colors.textMedium};

      ${downTo("md")} {
        font-size: ${typography.sizes.medium};
      }
    `
);

export const StyledCheckboxInput = styled.input`
  transform: scale(1.5);
  margin-right: 1rem;
`;

export const CheckboxInputContainer = styled.div(
  ({ theme: { downTo } }) =>
    css`
      margin: 0.5rem 1.5rem;
      ${downTo("xs")} {
        margin: 1.15rem 0rem;
      }
    `
);
