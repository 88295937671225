import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Functioning from "../../../../assets/functioning";
import { Bubble } from "../../bubble";
import { Description } from "../../description";
import { Col } from "../../../Grid";
import styled from "styled-components";
import Positioning from "../../Positioning";
import Pipe from "../../pipesMobile";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  MotionWrapper,
  elementVariant as bubbleVariant,
} from "../../animations";

const ValveBubble = styled(Bubble)`
  margin-bottom: 15px;
`;
const Valve = () => {
  const { t } = useTranslation("translation");
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);

  return (
    <>
      {inView && <Pipe number="6" />}
      {inView && <Pipe number="7" />}
      <Positioning topOffset="885" leftOffset="54">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <Col width="25%">
            <ValveBubble src={Functioning.WaterDrop} />
            <img src={Functioning.ValveMobile} alt="" />
          </Col>
        </MotionWrapper>
      </Positioning>
      {inView && <Pipe number="8" />}
      <Positioning topOffset="980" leftOffset="-150">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <Description
            title={t("functioning.valve.title")}
            subtitle={t("functioning.valve.description")}
          />
        </MotionWrapper>
      </Positioning>
      <Positioning topOffset="1100" leftOffset="-150">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <Description
            title={t("functioning.waterDrop.title")}
            subtitle={t("functioning.waterDrop.description")}
          />
        </MotionWrapper>
      </Positioning>
    </>
  );
};

export default Valve;
