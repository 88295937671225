import React from "react";
import { useTranslation } from "react-i18next";
import { Heading1 } from "../../../../../../components/text/Heading";
import { FunctioningContainer, FunctioningElements } from "./styles";
import Elements from "../../../../../../components/Functioning/elements";

const DesktopFunctioning = () => {
  const { t } = useTranslation("translation");

  return (
    <>
      <FunctioningContainer id="Functioning">
        <Heading1>{t("functioning.title")}</Heading1>
        <FunctioningElements>
          <Elements />
        </FunctioningElements>
      </FunctioningContainer>
    </>
  );
};

export default DesktopFunctioning;
