import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../../../button/DefaultButton";
import Feedback from "../../../feedback";

import { PurchaseButtonWrapper, PurchaseFeedbackWrapper } from "./styles";

const SuccessFeedback = () => {
  const { t } = useTranslation("translation");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <PurchaseFeedbackWrapper>
      <Feedback
        title={t("purchase.feedback.heading")}
        subtitle={t("purchase.feedback.description")}
      />

      <PurchaseButtonWrapper>
        <DefaultButton
          content={t("purchase.feedback.button")}
          destination="/"
          secondary
        />
      </PurchaseButtonWrapper>
    </PurchaseFeedbackWrapper>
  );
};

export default SuccessFeedback;
