/*----- Core -----*/
import React, { forwardRef } from "react";
import { useHistory } from "react-router-dom";

/*----- Components -----*/
import SocialNetworkLogos from "./SocialNetworks";
import SiteSections from "./Sections";
import {
  StyledFooter,
  FooterContainer,
  FooterRow,
  TextCol,
  SocialsCol,
  LogoCol,
  StLogoIcon,
} from "./styles";

const Footer = () => {
  const { push } = useHistory();

  return (
    <StyledFooter>
      <FooterContainer>
        <FooterRow>
          <LogoCol>
            <StLogoIcon onClick={() => push("/")} />
          </LogoCol>

          <TextCol>
            <SiteSections />
          </TextCol>

          <SocialsCol>
            <SocialNetworkLogos />
          </SocialsCol>
        </FooterRow>
      </FooterContainer>
    </StyledFooter>
  );
};

export default forwardRef(Footer);
