import React, { useEffect } from "react";
import styled from "styled-components";
import Pipe from "../../pipesMobile";
import Functioning from "../../../../assets/functioning";
import Positioning from "../../Positioning";
import { Description } from "../../description";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import {
  MotionWrapper,
  elementVariant as bubbleVariant,
} from "../../animations";

const StPlant = styled.img`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 70px;
`;

const Plant = () => {
  const { t } = useTranslation("translation");
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);

  return (
    <>
      {inView && <Pipe number="2" />}
      <Positioning topOffset="355" leftOffset="30">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <StPlant src={Functioning.Plant} alt="" />
        </MotionWrapper>
      </Positioning>
      <Positioning topOffset="520" leftOffset="25">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <Description
            title={t("functioning.humiditySensor.title")}
            subtitle={t("functioning.humiditySensor.description")}
          />
        </MotionWrapper>
      </Positioning>
    </>
  );
};
export default Plant;
