import JorgePalacioLorenzo from "./JorgePalacioLorenzo.png";
import LeandroCaamaño from "./LeandroCaamaño.png";
import MartinGutierrez from "./MartinGutierrez.png";

const CulbotTeam = {
  JorgePalacioLorenzo,
  LeandroCaamaño,
  MartinGutierrez,
};

export default CulbotTeam;
