import React from "react";
import "./commons/i18n";

import ViewsContainer from "./modules/Home/Views";
import { BrowserRouter } from "react-router-dom";
const App = () => (
  <BrowserRouter>
    <ViewsContainer />
  </BrowserRouter>
);

export default App;
