import styled from "styled-components";
import { ButtonWrapper } from "../../Form/styles";

export const PurchaseButtonWrapper = styled(ButtonWrapper)`
  justify-content: center;
  margin: 3rem 0;
`;

export const PurchaseFeedbackWrapper = styled.div`
  margin-top: 7rem;
`;
