import styled, { css } from "styled-components";

export const Bubble = styled.img(
  ({ theme: { downTo } }) => css`
    width: 120px;
    display: block;
    z-index: 998;
    ${downTo("lg")} {
      width: 80px;
      margin-bottom:0.35rem;
    }
  `
);

export const BubbleCol = styled.div(
  ({ theme: { downTo } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    ${downTo("lg")} {
      align-items: flex-end;
      margin-right: 2rem;
      & > div {
        margin-right: -2rem;
      }
    }
  `
);
