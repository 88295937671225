import styled from "styled-components";
import { Paragraph1 } from "../../../../../components/text/Paragraph";
export const AboutContainer = styled.div`
  position: relative;
  padding: 5rem 1rem;
`;

export const Image = styled.img`
  width: 100%;
  display: block;
  max-height: 50vh;
`;

export const CulbotDescription = styled(Paragraph1)`
  max-width: 560px;
  align-self: baseline;
`;
