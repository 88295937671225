import CulbotTeam from "../../../../../assets/whoWeAre/index";

export const Member = [
  {
    key: 1,
    name: "aboutUs.member1",
    image: CulbotTeam.LeandroCaamaño,
  },
  {
    key: 2,
    name: "aboutUs.member2",
    image: CulbotTeam.MartinGutierrez,
  },
  {
    key: 3,
    name: "aboutUs.member3",
    image: CulbotTeam.JorgePalacioLorenzo,
  },
];

export const Paragraph = [
  {
    key: 1,
    text: "aboutUs.paragraph1",
  },
  {
    key: 2,
    text: "aboutUs.paragraph2",
  },
];
