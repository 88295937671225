import React from "react";

import { FeedbackWrapper, FeedbackTitle, FeedbackSubtitle } from "./styles";

const Feedback = ({ title, subtitle }) => (
  <FeedbackWrapper
    animate={{ opacity: [0, 1], scale: [0.85, 1] }}
    transition={{ duration: 0.75, delay: 0.25 }}
  >
    <FeedbackTitle>{title}</FeedbackTitle>
    <FeedbackSubtitle>{subtitle}</FeedbackSubtitle>
  </FeedbackWrapper>
);
export default Feedback;
