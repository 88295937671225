import React from "react";
import SocialNetworks from "../../assets/social-networks";
import { StyledSocialNetworks, SocialNetworkWrapper } from "./styles";

const networks = [
  {
    destination: "",
    icon: SocialNetworks.facebook,
  },
  {
    destination: "",
    icon: SocialNetworks.instagram,
  },
  {
    destination: "",
    icon: SocialNetworks.youtube,
  },
];

const SocialNetworkLogos = ({ iconSize = "25px" }) => {
  const SocialNetwork = () => {
    return networks.map((social) => (
      <SocialNetworkWrapper href={social.destination} size={iconSize}>
        {social.icon}
      </SocialNetworkWrapper>
    ));
  };

  return <StyledSocialNetworks>{SocialNetwork()}</StyledSocialNetworks>;
};

export default SocialNetworkLogos;
