import React, { useState } from "react";
import propTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import {
  DatalistInput,
  DatalistContainer,
  StyledLabel,
  StyledErrorMessage,
} from "./styles";

const Datalist = ({ name, label, validate, options, list, handler }) => {
  const formikProps = useFormikContext();

  const handleChange = (e) => {
    formikProps.setFieldValue(name, e.target.value);
    setSearch(e.target.value);
    handler(e.target.value);
  };
  const [search, setSearch] = useState("");

  return (
    <DatalistContainer>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <Field
        name={name}
        type="search"
        value={search}
        list={list}
        validate={validate}
      >
        {({ field, meta }) => (
          <>
            <DatalistInput
              {...field}
              list={list}
              value={search}
              onChange={(e) => handleChange(e)}
            />
            <datalist id={list}>
              {options.map((option) => (
                <option key={option.id} value={option.nombre} hidden>
                  {option.nombre}, {option.provincia.nombre}.
                </option>
              ))}
            </datalist>
            {meta.touched && meta.error && (
              <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            )}
          </>
        )}
      </Field>
    </DatalistContainer>
  );
};

export default Datalist;

Datalist.propTypes = {
  /**
   Input name
   */
  name: propTypes.string,
  /**
   Input label
   */
  label: propTypes.string,
  /**
   Datalist id
   */
  list: propTypes.string,
  /**
   Datalist options with: id, title
   */
  options: propTypes.array,
  /**
   Datalist parent search handler
   */
  handler: propTypes.func,
  /**
   Formik validation
   */
  validate: propTypes.func,
};
