import styled, { css } from "styled-components";

export const Row = styled.div(
  ({
    theme: { downTo },
    paddingHorizontal = "9%",
    paddingHorizontalMobile = "7.5%",
    gap = "0%",
    width = "",
    justify = "",
    marginTopDesktop = "0",
    marginTopMobile = "0",
  }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: ${justify};
    padding: 0 ${paddingHorizontal};
    grid-column-gap: ${gap};
    width: ${width};
    margin-top: ${marginTopDesktop};
    ${downTo("md")} {
      padding: 0 ${paddingHorizontalMobile};
      margin-top: ${marginTopMobile};
    }
  `
);

export const Col = styled.div(
  ({
    theme: { downTo },
    width = "50%",
    paddingVerticalDesktop = "0",
    paddingHorizontalDesktop = "0",
    paddingVerticalMobile = "0",
    paddingHorizontalMobile = "0",
    float = "",
    alignDesktop = "center",
    alignMobile = "center",
    textAlign = "left",
    justify = "center",
    marginTopDesktop = "0",
    marginTopMobile = "0",
  }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: ${alignDesktop};
    justify-content: ${justify};
    width: ${width};
    min-width: 100px;
    padding: ${paddingVerticalDesktop} ${paddingHorizontalDesktop};
    float: ${float};
    text-align: ${textAlign};
    margin-top: ${marginTopDesktop};
    ${downTo("md")} {
      width: 100%;
      padding: ${paddingVerticalMobile} ${paddingHorizontalMobile};
      align-items: ${alignMobile};
      margin-top: ${marginTopMobile};
    }
  `
);

export const SectionRow = styled.div(
  ({ theme: { downTo }, paddingVerticalMobile = "0" }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 7.5%;
    ${downTo("md")} {
      padding: 0 0;
    }
  `
);
