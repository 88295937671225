import {
  MenuContainer,
  Content,
  ButtonWrapper,
  MenuOption,
  MenuButton,
  StHashLink,
  StNav,
} from "./styles";
import { useTranslation } from "react-i18next";

const Menu = ({ activeMenu }) => {
  const { t } = useTranslation();
  const menuVariants = {
    open: { opacity: 1, duration: 1, ease: "easeInOut" },
    closed: { opacity: 0, duration: 1, ease: "easeInOut" },
  };

  const options = t("header.menu", { returnObjects: true });

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 25;
    window.scrollTo({ top: yCoordinate - yOffset, behavior: "smooth" });
  };

  return (
    <MenuContainer
      animate={activeMenu ? "open" : "closed"}
      variants={menuVariants}
      transition={{ delay: 0.15 }}
    >
      <Content>
        <StNav>
          {options.map((option, index) => (
            <MenuOption key={index}>
              <StHashLink
                to={option.link}
                scroll={(el) => scrollWithOffset(el)}
              >
                {option.title}
              </StHashLink>
            </MenuOption>
          ))}
        </StNav>
        <ButtonWrapper>
          <MenuButton
            content={t("header.button")}
            destination="/acquire_equipment"
          />
        </ButtonWrapper>
      </Content>
    </MenuContainer>
  );
};

export default Menu;
