import React from "react";
import { StBurger } from "./styles";
import PropTypes from "prop-types";

const Burger = ({ activeMenu, toggle }) => (
  <StBurger activeMenu={activeMenu} onClick={toggle}>
    <span />
    <span />
    <span />
    <span />
  </StBurger>
);
export default Burger;

Burger.propTypes = {
  /* *
   *  Menu is open or closed
   */
  activeMenu: PropTypes.bool,
  /* *
   *  Menu toggle handler
   */
  toggle: PropTypes.func,
};
