import Positioning from "../Positioning";
import React from "react";
import { motion } from "framer-motion";
import { pipeVariant } from "../animations";

const Pipe1 = () => (
  <Positioning topOffset="70" leftOffset="70">
    <motion.svg width="41" height="77" viewBox="0 0 41 77" fill="none">
      <motion.path
        d="M0 1.07658L25.4383 1.07642C30.2922 0.580972 40 2.26549 40 12.9671V77"
        stroke="#293A5C"
        stroke-width="1.766"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe2 = () => (
  <Positioning topOffset="225" leftOffset="66">
    <motion.svg
      width="45"
      height="226"
      viewBox="0 0 45 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M43.9863 0V186.554C44.2129 194.834 41.7265 207.498 29.0295 207.498C15.515 207.498 30.5015 207.498 10.9453 207.498C3.37369 207.498 1 213.849 1 226"
        stroke="#293A5C"
        stroke-width="1.7"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);
const Pipe3 = () => (
  <Positioning topOffset="112" rightOffset="25">
    <motion.svg
      width="37"
      height="122"
      viewBox="0 0 37 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M15.4731 0.15918V13.9928C15.2769 16.4456 16.1795 21.0568 21.3597 19.8795H29.3067C31.4651 19.5851 35.6643 20.1149 35.1934 24.5888L34.899 113.525C34.9971 116.272 34.016 121.413 29.3066 120H0.914551"
        stroke="#293A5C"
        stroke-width="1.766"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe4 = () => (
  <Positioning topOffset="112" rightOffset="10">
    <motion.svg
      width="59"
      height="403"
      viewBox="0 0 59 403"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M44.8994 0.15918V5.45717C44.7032 7.90994 45.1937 11.7433 50.7861 11.3438C54.9067 11.0495 58.321 11.5793 57.85 16.0531L57.8501 394.642C57.9482 397.389 56.0841 402 47.2541 402H0.5"
        stroke="#293A5C"
        stroke-width="1.766"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe5 = () => (
  <Positioning topOffset="112" rightOffset="-20">
    <motion.svg
      width="99"
      height="650"
      viewBox="0 0 99 650"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M97.6779 0.15918V5.45717C97.8741 7.90994 97.3836 11.7433 91.7913 11.3438C87.6706 11.0495 84.2564 11.5793 84.7273 16.0531V637.521C84.6292 640.268 86.4497 649 76.4424 649H0.504616"
        stroke="#293A5C"
        stroke-width="1.766"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe6 = () => (
  <Positioning topOffset="112" leftOffset="20">
    <motion.svg
      width="46"
      height="816"
      viewBox="0 0 46 816"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M20.1039 0.15918V13.9928C20.1039 17.2305 19.4716 20.6994 7.99268 19.8795C3.87202 19.5851 0.457756 20.1149 0.928688 24.5888V807.142C0.830577 809.889 2.69469 814.5 11.5247 814.5H45.5"
        stroke="#293A5C"
        stroke-width="1.766"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe7 = () => (
  <Positioning topOffset="1072" leftOffset="103">
    <motion.svg
      width="2"
      height="73"
      viewBox="0 0 2 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M1 0L1 73"
        stroke="#293A5C"
        stroke-width="1.77"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe8 = () => (
  <Positioning topOffset="965" leftOffset="103">
    <motion.svg
      width="3"
      height="17"
      viewBox="0 0 3 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M1.91113 0V16.4235"
        stroke="#293A5C"
        stroke-width="1.89502"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe9 = () => (
  <Positioning topOffset="1217" leftOffset="16">
    <motion.svg
      width="62"
      height="40"
      viewBox="0 0 62 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M1.5 39.5L1.50001 14C1.50003 2.5 3.38744 1.00001 16.3744 1.00001L61.4999 1.00001"
        stroke="#293A5C"
        stroke-width="1.766"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe10 = () => (
  <Positioning topOffset="1233" leftOffset="110">
    <motion.svg
      width="2"
      height="24"
      viewBox="0 0 2 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M1 0L1 23.5"
        stroke="#293A5C"
        stroke-width="1.77"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe11 = () => (
  <Positioning topOffset="1200" leftOffset="-77">
    <motion.svg
      width="155"
      height="54"
      viewBox="0 0 155 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M1.12539 54L1.12539 14C0.338954 3.11507 3.01282 1.00001 15.9998 1.00001L154.5 1.00002"
        stroke="#293A5C"
        stroke-width="1.766"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe = ({ number }) => {
  switch (number) {
    case "1":
      return <Pipe1 />;
    case "2":
      return <Pipe2 />;
    case "3":
      return <Pipe3 />;
    case "4":
      return <Pipe4 />;
    case "5":
      return <Pipe5 />;
    case "6":
      return <Pipe6 />;
    case "7":
      return <Pipe7 />;
    case "8":
      return <Pipe8 />;
    case "9":
      return <Pipe9 />;
    case "10":
      return <Pipe10 />;
    case "11":
      return <Pipe11 />;
    default:
      return null;
  }
};
export default Pipe;
