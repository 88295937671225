import styled, { css } from "styled-components";

export const ContactContainer = styled.section`
  margin-bottom: 10%;
  margin-top: 10%;
  padding: 1rem;
  position: relative;
`;

export const ButtonWrapper = styled.div(
  ({ theme: { downTo } }) => css`
    button {
      position: relative;
      margin-top: 2rem;
      margin-left: 6.5%;
    }
    display: flex;
    flex-direction: row-reverse;
    ${downTo("md")} {
      button {
        margin-left: 0;
      }
      padding: 2.5% 0 15% 0;
    }
  `
);

export const FormRow = styled.div(
  ({ theme: { downTo } }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 7.5%;
    ${downTo("md")} {
      padding: 5% 0;
    }
  `
);
