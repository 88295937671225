import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  MotionWrapper,
  elementVariant as bubbleVariant,
} from "../../animations";
import Pipe from "../../pipes";

import Functioning from "../../../../assets/functioning";
import styled from "styled-components";
import Positioning from "../../Positioning";

const WaterDrops = styled.div`
  width: 353px;
  display: flex;
  justify-content: space-between;
`;

const WaterDrop = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);

  return (
    <>
      {inView && <Pipe number="7" />}
      <Positioning topOffset="1333" leftOffset="38">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <WaterDrops>
            <img src={Functioning.SmallWaterDrop} alt="" />
            <img src={Functioning.BigWaterDrop} alt="" />
            <img src={Functioning.SmallWaterDrop} alt="" />
          </WaterDrops>
        </MotionWrapper>
      </Positioning>
    </>
  );
};

export default WaterDrop;
