export const sections = [
  {
    key: 1,
    name: "footer.about",
    link: "/#About",
  },
  {
    key: 2,
    name: "footer.functions",
    link: "/#MainFeatures",
  },
  {
    key: 3,
    name: "footer.benefits",
    link: "/#Benefits",
  },
  {
    key: 4,
    name: "footer.functioning",
    link: "/#Functioning",
  },
  {
    key: 5,
    name: "footer.whoWeAre",
    link: "/#AboutUs",
  },
  {
    key: 6,
    name: "footer.contactUs",
    link: "/#Contact",
  },
];
