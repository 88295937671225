import React, { useEffect } from "react";
import Pipe from "../../pipesMobile";
import Functioning from "../../../../assets/functioning";
import Positioning from "../../Positioning";
import {
  MotionWrapper,
  elementVariant as bubbleVariant,
} from "../../animations";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const WaterDrop = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);

  return (
    <>
      {inView && <Pipe number="9" />}
      {inView && <Pipe number="10" />}
      {inView && <Pipe number="11" />}
      <Positioning topOffset="1145" leftOffset="76">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <img src={Functioning.WaterDropMobile} alt="" />
        </MotionWrapper>
      </Positioning>
      <Positioning topOffset="1265" leftOffset="101">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <img src={Functioning.SmallWaterDrop} width="20" alt="" />
        </MotionWrapper>
      </Positioning>
      <Positioning topOffset="1265" leftOffset="8">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <img src={Functioning.SmallWaterDrop} width="20" alt="" />
        </MotionWrapper>
      </Positioning>
      <Positioning topOffset="1265" leftOffset="-85">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <img src={Functioning.SmallWaterDrop} width="20" alt="" />
        </MotionWrapper>
      </Positioning>
    </>
  );
};
export default WaterDrop;
