import {
  DescriptionWrapper,
  DescriptionWrapperEnd,
  TitleEnd,
  SubtitleEnd,
  MobileSubtitle,
} from "./styles";
import { Paragraph1, Paragraph2, Paragraph3 } from "../../text/Paragraph";
import { isMobile } from "react-device-detect";

export const Description = ({ title, subtitle }) => (
  <DescriptionWrapper>
    {isMobile ? (
      <>
        <Paragraph3>{title}</Paragraph3>
        <MobileSubtitle>{subtitle}</MobileSubtitle>
      </>
    ) : (
      <>
        <Paragraph2>{title}</Paragraph2>
        <Paragraph1>{subtitle}</Paragraph1>
      </>
    )}
  </DescriptionWrapper>
);

export const DescriptionEnd = ({ title, subtitle }) => (
  <DescriptionWrapperEnd>
    <TitleEnd>{title}</TitleEnd>
    <SubtitleEnd>{subtitle}</SubtitleEnd>
  </DescriptionWrapperEnd>
);
