import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Feedback from "../../../feedback";
import { PurchaseFeedbackWrapper } from "./styles";

const ErrorFeedback = () => {
  const { t } = useTranslation("translation");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <PurchaseFeedbackWrapper>
      <Feedback
        title={t("contact.feedback.error.heading")}
        subtitle={t("contact.feedback.error.description")}
      />
    </PurchaseFeedbackWrapper>
  );
};

export default ErrorFeedback;
