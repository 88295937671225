import Benefits from "../../../../../assets/benefits/index";

export const Row1 = [
  {
    key: 1,
    image: Benefits.Notifications,
    altText: "benefits.alts.4",
    text: "benefits.benefits.4",
    marginTop: "-50px",
    marginLeft: "200px",
  },
];

export const Row2 = [
  {
    key: 1,
    image: Benefits.Monitoring,
    altText: "benefits.alts.0",
    text: "benefits.benefits.0",
    width: "27%",
    textWidth: "180px",
  },
  {
    key: 2,
    image: Benefits.Watering,
    altText: "benefits.alts.2",
    text: "benefits.benefits.2",
    width: "40%",
    textWidth: "200px",
  },
];

export const Row3 = [
  {
    key: 1,
    image: Benefits.Yields,
    altText: "benefits.alts.1",
    text: "benefits.benefits.1",
    marginTop: "100px",
    width: "55%",
  },
  {
    key: 2,
    image: Benefits.Presets,
    altText: "benefits.alts.3",
    text: "benefits.benefits.3",
    marginTop: "-150px",
    width: "33%",
  },
];
