import React, { useContext, useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeContext } from "../../../commons/Theme/store";
import Navbar from "../../../components/Navbar";
import AcquireEquipment from "./sections/AcquireEquipment";
import About from "./sections/About";
import MainFeatures from "./sections/MainFeatures";
import EnterNow from "./sections/EnterNow";
import Benefits from "./sections/Benefits";
import WhoWeAre from "./sections/WhoWeAre";
import Contact from "./sections/Contact";
import Functioning from "./sections/Functioning";
import AcquireContact from "./sections/AcquireContact";
import Footer from "../../../components/Footer";

const Home = () => {
  const { changeTheme } = useContext(ThemeContext);
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
    changeTheme("dark");
  }, [changeTheme]);
  return (
    <>
      <AcquireEquipment />
      <About />
      <MainFeatures />
      <Benefits />
      <Functioning />
      <EnterNow />
      <WhoWeAre />
      <Contact />
    </>
  );
};

const ViewsContainer = () => (
  <>
    <Navbar />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/acquire_equipment" component={AcquireContact} />
    </Switch>
    <Footer />
  </>
);

export default ViewsContainer;
