
export const ContactBody = (data) =>
  JSON.stringify({
    sender: data.user,
    sender_email: process.env.REACT_APP_SENDER_EMAIL,
    recipients: [process.env.REACT_APP_RECIPIENT_EMAIL],
    subject: "Contacto (culbot.com)",
    template_name: "culbot-send-message",
    template_params: data,
  });

export const AcquireEquipmentBody = (data) =>
  JSON.stringify({
    sender: data.user,
    sender_email: process.env.REACT_APP_SENDER_EMAIL,
    recipients: [process.env.REACT_APP_RECIPIENT_EMAIL],
    subject: "Adquirir equipo (culbot.com)",
    template_name: "culbot-acquire-device",
    template_params: data,
  });
