import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Functioning from "../../../../assets/functioning";
import { Bubble, BubbleCol } from "../../bubble";
import { Description } from "../../description";
import Positioning from "../../Positioning";
import Pipe from "../../pipesMobile";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  MotionWrapper,
  elementVariant as bubbleVariant,
} from "../../animations";

const BubbleElementMobile = ({
  title,
  description,
  top,
  right,
  left,
  bubbleImg,
  pipe,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);
  return (
    <>
      {inView && <Pipe number={pipe} />}
      <Positioning
        topOffset={top}
        rightOffset={right}
        leftOffset={left}
        direction="row-reverse"
      >
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <BubbleCol>
            <Bubble src={bubbleImg} />
            <Description title={title} subtitle={description} />
          </BubbleCol>
        </MotionWrapper>
      </Positioning>
    </>
  );
};

const BubbleElements = () => {
  const { t } = useTranslation("translation");

  const elements = [
    {
      title: t("functioning.extractor.title"),
      description: t("functioning.extractor.description"),
      top: "197",
      right: "15",
      left: "",
      bubbleImg: Functioning.Extractor,
      pipe: "3",
    },
    {
      title: t("functioning.illumination.title"),
      description: t("functioning.illumination.description"),
      top: "475",
      right: "15",
      left: "",
      bubbleImg: Functioning.Illumination,
      pipe: "4",
    },
    {
      title: t("functioning.fan.title"),
      description: t("functioning.fan.description"),
      top: "720",
      right: "15",
      left: "",
      bubbleImg: Functioning.Fan,
      pipe: "5",
    },
    {
      title: "",
      description: "",
      top: "145",
      right: "",
      left: "25",
      bubbleImg: Functioning.HumiditySensor,
      pipe: "1",
    },
  ];

  return (
    <>
      {elements.map((element, index) => (
        <BubbleElementMobile
          key={index}
          title={element.title}
          description={element.description}
          top={element.top}
          right={element.right}
          left={element.left}
          bubbleImg={element.bubbleImg}
          pipe={element.pipe}
        />
      ))}
    </>
  );
};

export default BubbleElements;
