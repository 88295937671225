import styled from "styled-components";
import Functioning from "../../../../assets/functioning";

const EquipmentImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  width: 140px;
  left: calc(50% - 70px);
`;
const Equipment = () => <EquipmentImage src={Functioning.Equipment} />;

export default Equipment;
