import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";

import { ContactContainer, ButtonWrapper, FormRow } from "./styles";
import { sendEmail } from "../../../../../utils/mailer";
import { contactValidation } from "../../../../../utils/form/validations";

import TextInput from "../../../../../components/form/input/text";
import { Col } from "../../../../../components/Grid";
import { Heading1 } from "../../../../../components/text/Heading";
import DefaultButton from "../../../../../components/button/DefaultButton";
import Feedback from "../../../../../components/feedback";

const Contact = () => {
  const parseValues = (values) => {
    let parsedValues = {
      user: values.name,
      contact_data: values.email,
      message: values.message,
    }
    onClick(parsedValues)
  }

  const onClick = async (values) => {
    const response = await sendEmail({ data: values });
    setSent(true);
    response.hasOwnProperty('answers') && setSuccess(true);
  };
  const { t } = useTranslation("translation");
  const [sent, setSent] = useState(false);
  const [success, setSuccess] = useState(false);
  return (
    <ContactContainer id="Contact">
      <FormRow>
        <Heading1>{t("contact.title")}</Heading1>
      </FormRow>
      {!sent ? (
        <Formik
          initialValues={{
            email: "",
            name: "",
            message: "",
          }}
          validationSchema={contactValidation}
          onSubmit={(values) => {
            parseValues(values);
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <FormRow>
                <Col>
                  <TextInput
                    type="text"
                    label={t("contact.fields.name")}
                    name="name"
                  />
                  <TextInput
                    type="text"
                    label={t("contact.fields.email")}
                    name="email"
                  />
                </Col>
                <Col>
                  <TextInput
                    type="textarea"
                    label={t("contact.fields.message")}
                    name="message"
                  />
                </Col>
              </FormRow>
              <ButtonWrapper>
                <Col alignMobile="flex-start" alignDesktop="flex-start">
                  <DefaultButton
                    disabled={isSubmitting || Object.entries(errors).length > 0}
                    type="submit"
                    content={t("contact.button")}
                    secondary
                  />
                </Col>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      ) : success ? (
        <Feedback
          title={t("contact.feedback.heading")}
          subtitle={t("contact.feedback.description")}
        />
      ) : (
        <Feedback
          title={t("contact.feedback.error.heading")}
          subtitle={t("contact.feedback.error.description")}
        />
      )}
    </ContactContainer>
  );
};

export default Contact;
