import React from "react";
import { useTranslation } from "react-i18next";
import { StyledCol, Paragraph, StHashLink } from "./styles";
import { sections } from "./utils";
import { Row } from "../Grid";

const SiteSections = () => {
  const { t } = useTranslation("translation");

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 80;
    window.scrollTo({ top: yCoordinate - yOffset, behavior: "smooth" });
  };

  const Section = () => {
    return sections.map((sect) => (
      <StyledCol>
        <Paragraph>
          <StHashLink to={sect.link} scroll={(el) => scrollWithOffset(el)}>
            {t(sect.name)}
          </StHashLink>
        </Paragraph>
      </StyledCol>
    ));
  };

  return (
    <Row width="100%">
      <Section />
    </Row>
  );
};

export default SiteSections;
