import React, { useContext, useLayoutEffect, useState } from "react";
import { sendEmail } from "../../../../../utils/mailer";
import { ThemeContext } from "../../../../../commons/Theme/store";
import FormResponse from "../../../../../components/form/response";
import ErrorFeedback from "../../../../../components/AcquireContact/Feedback/Error";
import SuccessFeedback from "../../../../../components/AcquireContact/Feedback/Success";
import AcquireContactForm from "../../../../../components/AcquireContact/Form";
import { AquireEquipmentContainer } from "./styles";

const AcquireContact = () => {
  const [sent, setSent] = useState(false);
  const [success, setSuccess] = useState(false);

  const { changeTheme } = useContext(ThemeContext);

  const submitForm = async (values) => {
    const response = await sendEmail({ data: values, acquiring: true });
    setSent(true);
    console.log(response);
    response.hasOwnProperty('answers') && setSuccess(true);
  };

  // Theme Switching on mount
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
    changeTheme("light");
  }, [changeTheme]);

  const Form = () => (
    <AcquireContactForm submitForm={(values) => submitForm(values)} />
  );

  return (
    <AquireEquipmentContainer>
      <FormResponse
        sent={sent}
        success={success}
        Form={Form}
        Error={ErrorFeedback}
        Success={SuccessFeedback}
      />
    </AquireEquipmentContainer>
  );
};

export default AcquireContact;
