import styled from "styled-components";
import { motion } from "framer-motion";

export const MotionWrapper = styled(motion.div)`
  display: inherit;
  flex-direction: inherit;
`;

export const elementVariant = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.75,
      delay: 0.4,
    },
  },
};

export const pipeVariant = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
  },
};
