import { ReactComponent as FacebookLogo } from "./facebook-logo.svg";
import { ReactComponent as InstagramLogo } from "./instagram-logo.svg";
import { ReactComponent as YoutubeLogo } from "./youtube-logo.svg";

const SocialNetworks = {
  facebook: <FacebookLogo stroke="none" fill="none" />,
  instagram: <InstagramLogo stroke="none" fill="none" />,
  youtube: <YoutubeLogo stroke="none" fill="none" />,
};

export default SocialNetworks;
