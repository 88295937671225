import styled, { css } from "styled-components";

import Backgrounds from "../../../../../assets/backgrounds/index";
import { Heading1 } from "../../../../../components/text/Heading";

export const EnterNowButtonWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  button {
    position: relative !important;
    padding: 1.5rem;
  }
`;

export const EnterNowHeading = styled(Heading1)(
  ({ theme: { colors, downTo } }) => css`
    color: ${colors.neutral00};
    font-family: "InterSemiBold";
    ${downTo("md")} {
      width: 65%;
      margin: 0;
      align-self: flex-start;
    }
  `
);

export const EnterNowContainer = styled.div(
  ({ theme: { downTo, upTo } }) => css`
    position: relative;
    background-image: url(${Backgrounds.EnterNowDesktop});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 1000px;

    ${upTo("xxl")} {
      height: 1200px;
    }
    ${downTo("md")} {
      height: 1150px;
    }
    ${downTo("sm")} {
      background-image: url(${Backgrounds.EnterNowMobile});
      height: 700px;
    }
  `
);

export const GridContainer = styled.div(
  ({ theme: { downTo, upTo } }) => css`
    padding-top: 275px;
    ${downTo("sm")} {
      padding-top: 125px;
    }
    ${upTo("xxl")} {
      padding-top: 300px;
    }
  `
);

export const Image = styled.img`
  max-width: 100%;
`;
