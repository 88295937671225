import styled from "styled-components";

export const FunctioningContainer = styled.section`
  position: relative;
  min-height: 1550px;
  margin: 5% 10%;
`;
export const FunctioningElements = styled.div`
  position: relative;
  margin: 2rem 0;
`;
