import React from "react";
import BubbleElements from "./BubbleElements";
import Equipment from "./Others/Equipment";
import Plant from "./Others/Plant";
import Valve from "./Others/Valve";
import WaterDrop from "./Others/WaterDrop";

const ElementsMobile = () => (
  <>
    <Equipment />
    <BubbleElements />
    <Plant />
    <Valve />
    <WaterDrop />
  </>
);

export default ElementsMobile;
