import React from "react";
import { Col, SectionRow } from "../../../../../components/Grid";
import Card from "../../../../../components/MainFeatures/Card";
import { MainFeaturesContainer } from "./styles";
import { Heading1 } from "../../../../../components/text/Heading";
import MainFunctionsImages from "../../../../../assets/mainFunctions";
import { useTranslation } from "react-i18next";

const MainFeatures = () => {
  const { t } = useTranslation("translation");
  const features = t("features.features", { returnObjects: true });
  const images = Object.values(MainFunctionsImages);

  return (
    <MainFeaturesContainer id="MainFeatures">
      <SectionRow>
        <Heading1>{t("features.title")}</Heading1>
      </SectionRow>
      <SectionRow>
        {features.map((feature, index) => (
          <Col width="50%" key={index}>
            <Card
              image={images[index]}
              title={feature.title}
              description={feature.description}
            />
          </Col>
        ))}
      </SectionRow>
    </MainFeaturesContainer>
  );
};

export default MainFeatures;
