import styled, { css } from "styled-components";

export const StBurger = styled.div(
  ({ theme: { navbar, colors }, activeMenu }) => css`
    width: 38px;
    height: 38px;
    position: relative;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 38px;
      background: ${activeMenu ? colors.neutral00 : navbar.color};
      border-radius: 6px;
      opacity: 1;
      margin: 0;
      padding: 0;

      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 5px;
    }

    span:nth-child(2) {
      top: 15px;
      width: 26px;
      right: 0;
    }

    span:nth-child(3) {
      top: 25px;
      width: 32px;
      right: 0;
    }
    span:nth-child(4) {
      top: 5px;
    }

    ${activeMenu &&
    css`
      span {
        width: 38px;
      }
      span:nth-child(1) {
        top: 15px;
        width: 32px;
        right: 5px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      span:nth-child(4) {
        opacity: 0;
      }
      span:nth-child(2) {
        opacity: 0;
      }
      span:nth-child(3) {
        top: 15px;
        right: 5px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    `}
  `
);
