import React from "react";
import BubbleElements from "./BubbleElements";
import Equipment from "./Others/Equipment";
import Account from "./Others/Account";
import Valve from "./Others/Valve";
import Plant from "./Others/Plant";
import WaterDrop from "./Others/WaterDrop";

const Elements = () => (
  <>
    <BubbleElements />
    <Equipment />
    <Account />
    <Valve />
    <WaterDrop />
    <Plant />
  </>
);

export default Elements;
