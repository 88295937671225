import BenefitsDesktop from "./benefits-background-desktop.png";
import BenefitsMobile from "./benefits-background-mobile.png";
import EnterNowDesktop from "./enter-now-background-desktop.svg";
import EnterNowMobile from "./enter-now-background-mobile.svg";
import FooterDesktop from "./footer-background-desktop.svg";
import FooterMobile from "./footer-background-mobile.svg";
import AcquireEquipmentDesktop from "./header-background-desktop.svg";
import AcquireEquipmentMobile from "./header-background-mobile.svg";

const Backgrounds = {
  BenefitsDesktop,
  BenefitsMobile,
  EnterNowDesktop,
  EnterNowMobile,
  FooterDesktop,
  FooterMobile,
  AcquireEquipmentDesktop,
  AcquireEquipmentMobile,
};

export default Backgrounds;
