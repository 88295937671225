import styled, { css } from "styled-components";
import { Col } from "../../Grid";

export const SectionTitleContainer = styled.div(
  ({ theme: { downTo } }) => css`
    padding: 5% 7.5%;
    h1 {
      margin: 1rem 0;
    }
    ${downTo("md")} {
      padding: 5% 0;
    }
  `
);

export const ButtonWrapper = styled.div(
  ({ theme: { downTo } }) => css`
    button {
      position: relative;
      margin-top: 2rem;
    }
    display: flex;
    flex-direction: row;
    padding: 0 7.5%;
    ${downTo("md")} {
      padding: 2.5% 0 15% 0;
    }
  `
);

export const FormRow = styled.div(
  ({ theme: { downTo } }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 7.5%;
    ${downTo("md")} {
      padding: 5% 0;
    }
  `
);
export const OptionsError = styled.div(
  ({ theme: { colors, typography, downTo } }) => css`
    font-size: ${typography.sizes.medium};
    font-family: "InterRegular";
    color: ${colors.feedbackErrorMedium};
    position: absolute;
    bottom: -10px;
  `
);

export const CheckboxWrapper = styled.div`
  display: block;
  position: absolute;
  top: 2rem;
`;

export const ContactCol = styled(Col)`
  position: relative;
`;
export const ContactInputWrapper = styled.div(
  ({ theme: { downTo } }) => css`
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 1;
    transition: 1s opacity ease-in-out;
    ${downTo("md")} {
      position: relative;
      margin-top: 7rem;
    }
  `
);
