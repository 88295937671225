import Yields from "./yields.svg";
import Presets from "./presets.svg";
import Watering from "./watering.svg";
import Monitoring from "./monitoring.svg";
import Notifications from "./notifications.svg";

const Benefits = {
  Yields,
  Presets,
  Watering,
  Monitoring,
  Notifications,
};

export default Benefits;
