import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Functioning from "../../../../assets/functioning";
import { Bubble } from "../../bubble";
import { Description } from "../../description";
import { useTranslation } from "react-i18next";
import { Row, Col } from "../../../Grid";
import styled from "styled-components";
import Positioning from "../../Positioning";
import {
  MotionWrapper,
  elementVariant as bubbleVariant,
} from "../../animations";
import Pipe from "../../pipes";

const ValveDescriptions = styled(Row)`
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  width: 100%;
  & > div {
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

const Valve = () => {
  const { t } = useTranslation("translation");
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });
  const elements = [
    t("functioning.valve", { returnObjects: true }),
    t("functioning.waterDrop", { returnObjects: true }),
  ];

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);
  return (
    <>
      {inView && <Pipe number="6" />}
      <Positioning topOffset="715" leftOffset="155">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <Col width="25%">
            <Bubble src={Functioning.WaterDrop} />
            <img src={Functioning.Valve} alt="" />
          </Col>
          <ValveDescriptions>
            {elements.map((element, index) => (
              <Col width="100%" key={index}>
                <Description
                  title={element.title}
                  subtitle={element.description}
                />
              </Col>
            ))}
          </ValveDescriptions>
        </MotionWrapper>
      </Positioning>
    </>
  );
};

export default Valve;
