import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { Col } from "../../Grid";
import { Heading1 } from "../../text/Heading";
import { acquireEquipmentValidation } from "../../../utils/form/validations";
import { Paragraph1 } from "../../text/Paragraph";
import TextInput from "../../form/input/text";
import DefaultButton from "../../button/DefaultButton";
import Checkbox from "../../form/input/checkbox";
import Datalist from "../../form/datalist";

import {
  ButtonWrapper,
  SectionTitleContainer,
  OptionsError,
  FormRow,
  CheckboxWrapper,
  ContactCol,
  ContactInputWrapper,
} from "./styles";
import { fetchCities } from "../../../utils/api";

const AcquireContactForm = ({ submitForm }) => {
  const { t } = useTranslation("translation");
  const [cities, setCities] = useState([]);
  const [search, setSearch] = useState("");
  const [checkedItems, setCheckedItems] = useState("");
  const [optionsError, setOptionsError] = useState(false);

  const checkboxes = [
    {
      id: "WhatsApp",
      name: "option_whatsapp",
      label: t("purchase.fields.checkbox.phone"),
    },
    {
      id: "Mail",
      name: "option_mail",
      label: t("purchase.fields.checkbox.mail"),
    },
  ];

  const handleSearch = (value) => {
    setSearch(value);
  };

  const ValidateOptions = () => {
    checkedItems.length === 0 && setOptionsError(true);
  };

  const handleCheckChange = (e) => {
    setCheckedItems(e.target.id);
    optionsError && setOptionsError(false);
  };

  const parseValues = (values) => {
    let parsedValues = {
      user: values.fullname,
      place: values.city,
      contact_from: checkedItems,
      contact_data: values.phone || values.mail,
    }
    if (checkedItems === 'WhatsApp'){
      parsedValues.link = "https://web.whatsapp.com/send/?phone=549" + values.phone
    }
    submitForm(parsedValues)
  }

  useEffect(() => {
    search && fetchCities(search).then((result) => setCities(result));
  }, [search]);

  return (
    <>
      <SectionTitleContainer>
        <Heading1>{t("purchase.title")}</Heading1>
        <Paragraph1>{t("purchase.description")}</Paragraph1>
      </SectionTitleContainer>
      <Formik
        initialValues={{
          fullname: "",
          city: "",
          phone: "",
          mail: "",
        }}
        validationSchema={acquireEquipmentValidation}
        onSubmit={(values) => 
          parseValues(values)
        }
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <FormRow>
              <Col alignDesktop="flex-start" alignMobile="flex-start">
                <TextInput
                  type="text"
                  label={t("purchase.fields.fullname")}
                  name="fullname"
                />
                <Datalist
                  name="city"
                  label={t("purchase.fields.city")}
                  list="cities"
                  options={cities}
                  value={search}
                  handler={handleSearch}
                />
              </Col>
              <ContactCol alignDesktop="flex-start" alignMobile="flex-start">
                <CheckboxWrapper>
                  {checkboxes.map((item) => (
                    <Checkbox
                      id={item.id}
                      checked={checkedItems === item.id}
                      onChange={handleCheckChange}
                      name={item.name}
                      label={item.label}
                    />
                  ))}
                  <OptionsError>
                    {optionsError && t("purchase.fields.checkbox.error")}
                  </OptionsError>
                </CheckboxWrapper>
                <ContactInputWrapper>
                  {checkedItems === "WhatsApp" && (
                    <TextInput
                      type="text"
                      label={t("purchase.fields.phone")}
                      name="phone"
                    />
                  )}

                  {checkedItems === "Mail" && (
                    <TextInput
                      type="text"
                      label={t("purchase.fields.mail")}
                      name="mail"
                    />
                  )}
                </ContactInputWrapper>
              </ContactCol>
            </FormRow>
            <ButtonWrapper>
              <Col alignDesktop="flex-start" alignMobile="flex-start">
                <DefaultButton
                  disabled={isSubmitting || Object.entries(errors).length > 0}
                  type="submit"
                  content={t("purchase.button")}
                  secondary
                  handleClick={ValidateOptions}
                />
              </Col>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AcquireContactForm;
