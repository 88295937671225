import { Col, SectionRow } from "../../../../../components/Grid";
import { AboutContainer, Image, CulbotDescription } from "./styles";
import { Heading1 } from "../../../../../components/text/Heading";

import AboutImage from "../../../../../assets/aboutUs";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const About = () => {
  const { t } = useTranslation("translation");
  return (
    <AboutContainer id="About">
      <SectionRow>
        <Col>
          {isMobile && <Heading1>{t("aboutCulbot.title")}</Heading1>}
          <Image
            src={AboutImage.CulbotEquipment}
            alt={t("aboutCulbot.title")}
          />
        </Col>
        <Col>
          {!isMobile && <Heading1>{t("aboutCulbot.title")}</Heading1>}
          <CulbotDescription>{t("aboutCulbot.description")}</CulbotDescription>
        </Col>
      </SectionRow>
    </AboutContainer>
  );
};

export default About;
