import Positioning from "../Positioning";
import React from "react";
import { motion } from "framer-motion";
import { pipeVariant } from "../animations";

const Pipe1 = () => (
  <Positioning topOffset="165" leftOffset="120">
    <motion.svg fill="none">
      <motion.path
        d="M0 2.12854H74C81.8333 1.2952 97.5 4.12853 97.5 22.1285V54.1284"
        stroke="#293A5C"
        strokeWidth="3"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);
const Pipe2 = () => (
  <Positioning topOffset="192" rightOffset="40">
    <motion.svg width="179" height="157" viewBox="0 0 179 157" fill="none">
      <motion.path
        d="M143.5 0V23.5C143.167 27.6667 144.7 35.5 153.5 33.5H167C170.667 33 177.8 33.9 177 41.5V144C177.167 148.667 175.5 157.4 167.5 155H0"
        stroke="#293A5C"
        strokeWidth="3"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe3 = () => (
  <Positioning topOffset="192" rightOffset="10">
    <motion.svg width="173" height="360" viewBox="0 0 173 360" fill="none">
      <motion.path
        d="M148.5 0V9C148.167 13.1667 149 19.6786 158.5 19C165.5 18.5 171.3 19.4 170.5 27V346C170.667 350.667 167.5 358.5 152.5 358.5H0.5"
        stroke="#293A5C"
        strokeWidth="3"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe4 = () => (
  <Positioning topOffset="192" leftOffset="-135">
    <motion.svg width="167" height="549" viewBox="0 0 167 549" fill="none">
      <motion.path
        d="M165.074 0V9C165.407 13.1667 164.574 19.6786 155.074 19C148.074 18.5 142.274 19.4 143.074 27V527.5C142.907 532.167 146 547 129 547H0.00012207"
        stroke="#293A5C"
        strokeWidth="3"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe5 = () => (
  <Positioning topOffset="340" leftOffset="215">
    <motion.svg width="148" height="279" viewBox="0 0 148 279" fill="none">
      <motion.path
        d="M2.02012 0V230.303C1.68713 240.525 5.34065 256.159 23.9974 256.159C43.8555 256.159 102.651 256.159 131.386 256.159C142.512 256.159 146 264 146 279"
        stroke="#293A5C"
        strokeWidth="3"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe6 = () => (
  <Positioning topOffset="192" leftOffset="35">
    <motion.svg width="159" height="589" viewBox="0 0 159 589" fill="none">
      <motion.path
        d="M35.074 0V23.5C35.074 29 34 34.8929 14.5 33.5C7.5 33 1.7 33.9 2.5 41.5V575C2.33333 579.667 5.5 587.5 20.5 587.5H159"
        stroke="#293A5C"
        strokeWidth="3"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);
const Pipe7 = () => (
  <Positioning topOffset="1150" leftOffset="54">
    <motion.svg width="319" height="192" viewBox="0 0 319 192" fill="none">
      <motion.path
        d="M160.538 0.5V31M2.0377 192V112.5C1.70437 108.333 3.4377 100 13.0377 100C22.6377 100 51.371 100 64.5377 100C68.0377 99.6667 75.0377 96.8 75.0377 88C75.0377 79.2 75.0377 52.6667 75.0377 40.5C74.871 36.8333 76.1377 29.8 82.5377 31C85.8188 31.6152 128.72 31.731 160.538 31.6304M317.038 190C317.371 165.333 317.838 114.4 317.038 108C316.238 101.6 310.038 100.333 307.038 100.5C292.204 100.333 260.838 100.1 254.038 100.5C247.238 100.9 244.538 93 244.038 89C243.538 74.6667 242.838 44.4 244.038 38C245.238 31.6 236.871 30.6667 232.538 31C209.471 31.2437 190.783 31.5348 160.538 31.6304M160.538 31.6304V110.815V168.5"
        stroke="#293A5C"
        strokeWidth="3"
        initial="hidden"
        animate="visible"
        variants={pipeVariant}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: "easeIn" },
        }}
      />
    </motion.svg>
  </Positioning>
);

const Pipe = ({ number }) => {
  switch (number) {
    case "1":
      return <Pipe1 />;
    case "2":
      return <Pipe2 />;
    case "3":
      return <Pipe3 />;
    case "4":
      return <Pipe4 />;
    case "5":
      return <Pipe5 />;
    case "6":
      return <Pipe6 />;
    case "7":
      return <Pipe7 />;
    default:
      return null;
  }
};

export default Pipe;
