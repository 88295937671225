import styled, { css } from "styled-components";
import { Heading2 } from "../text/Heading";
import { Paragraph3 } from "../text/Paragraph";
import { motion } from "framer-motion";
export const FeedbackWrapper = styled(motion.dialog)(
  ({ theme: { colors, downTo, upTo } }) => css`
    position: relative;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.mainPrimary20};
    padding: 3rem 6rem;
    ${downTo("md")} {
      padding: 1.5rem 3rem;
      margin: 2rem;
    }
    ${upTo("xl")} {
      padding: 3rem 12rem;
    }
  `
);

export const FeedbackTitle = styled(Heading2)(
  ({ theme: { colors } }) => css`
    text-align: center;
    color: ${colors.textMedium};
  `
);

export const FeedbackSubtitle = styled(Paragraph3)(
  ({ theme: { colors } }) => css`
    text-align: center;
    color: ${colors.textMedium};
  `
);
