import React from "react";
import {
  StyledCheckboxInput,
  StyledLabel,
  CheckboxInputContainer,
} from "./styles";

const Checkbox = ({ type = "radio", name, id, label, checked, onChange }) => {
  return (
    <CheckboxInputContainer>
      <StyledCheckboxInput
        type={type}
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <StyledLabel>{label}</StyledLabel>
    </CheckboxInputContainer>
  );
};

export default Checkbox;
