import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  MotionWrapper,
  elementVariant as bubbleVariant,
} from "../../animations";
import Pipe from "../../pipes";

import Functioning from "../../../../assets/functioning";
import Positioning from "../../Positioning";

const Plant = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);

  return (
    <>
      {inView && <Pipe number="5" />}
      <Positioning topOffset="400" leftOffset="280">
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          <img src={Functioning.Plant} alt="" />
        </MotionWrapper>
      </Positioning>
    </>
  );
};
export default Plant;
