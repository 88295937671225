import styled, { css } from "styled-components";
import { Paragraph1 } from "../../../../../components/text/Paragraph";

export const WhoWeAreContainer = styled.div`
  overflow-x: hidden;
  margin: 5vh 0px;
`;

export const ParagraphContainer = styled(Paragraph1)`
  margin: 0.5rem 0;
`;

export const Image = styled.img(
  ({ theme: { downTo } }) => css`
    width: 100%;
    display: block;

    ${downTo("sm")} {
      height: auto;
      max-width: 70vw;
    }
  `
);

export const ImageContainer = styled.div(
  ({ theme: { downTo } }) => css`
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    margin: 5vh 15px;
    max-width: 240px;
    ${downTo("md")} {
      height: auto;
      margin: 0px 10px;
    }
  `
);

export const StyledParagraph1 = styled(Paragraph1)`
  display: none;
  color: #ffffff;
`;

export const TextContainer = styled.div(
  ({ theme: { colors } }) => css`
    height: 100%;
    width: 100%;
    position: absolute;

    &:hover {
      background-color: ${colors.mainPrimary40};
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      transition: 0.2s background-color ease-in-out;

      p {
        display: block;
        position: absolute;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  `
);
