import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Functioning from "../../../../assets/functioning";
import { Bubble, BubbleCol } from "../../bubble";
import { Description, DescriptionEnd } from "../../description";
import { useTranslation } from "react-i18next";
import Positioning from "../../Positioning";
import Pipe from "../../pipes";
import {
  MotionWrapper,
  elementVariant as bubbleVariant,
} from "../../animations";

const BubbleElement = ({
  title,
  description,
  top,
  right,
  left,
  endAlign,
  bubbleImg,
  pipe,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);

  return (
    <>
      {inView && <Pipe number={pipe} />}
      <Positioning
        topOffset={top}
        rightOffset={right}
        leftOffset={left}
        direction={endAlign && "row-reverse"}
      >
        <MotionWrapper
          as={motion.div}
          initial="hidden"
          variants={bubbleVariant}
          ref={ref}
          animate={controls}
        >
          {endAlign ? (
            <Description title={title} subtitle={description} />
          ) : (
            <DescriptionEnd title={title} subtitle={description} />
          )}
          <BubbleCol>
            <Bubble src={bubbleImg} />
          </BubbleCol>
        </MotionWrapper>
      </Positioning>
    </>
  );
};

const BubbleElements = () => {
  const { t } = useTranslation("translation");

  const elements = [
    {
      title: t("functioning.extractor.title"),
      description: t("functioning.extractor.description"),
      top: "290",
      right: "180",
      left: "",
      endAlign: false,
      bubbleImg: Functioning.Extractor,
      pipe: "2",
    },
    {
      title: t("functioning.humiditySensor.title"),
      description: t("functioning.humiditySensor.description"),
      top: "220",
      right: "",
      left: "155",
      endAlign: true,
      bubbleImg: Functioning.HumiditySensor,
      pipe: "1",
    },
    {
      title: t("functioning.fan.title"),
      description: t("functioning.fan.description"),
      top: "680",
      right: "80",
      left: "",
      endAlign: false,
      bubbleImg: Functioning.Fan,
      pipe: "4",
    },
    {
      title: t("functioning.illumination.title"),
      description: t("functioning.illumination.description"),
      top: "495",
      right: "130",
      left: "",
      endAlign: false,
      bubbleImg: Functioning.Illumination,
      pipe: "3",
    },
  ];

  return (
    <>
      {elements.map((element, index) => (
        <BubbleElement
          key={index}
          title={element.title}
          description={element.description}
          top={element.top}
          right={element.right}
          left={element.left}
          endAlign={element.endAlign}
          bubbleImg={element.bubbleImg}
          pipe={element.pipe}
        />
      ))}
    </>
  );
};

export default BubbleElements;
