import BigWaterDrop from "./big-water-drop.svg";
import SmallWaterDrop from "./small-water-drop.svg";
import Extractor from "./extractor.svg";
import Illumination from "./illumination.svg";
import Fan from "./fan.svg";
import Account from "./account.svg";
import HumiditySensor from "./humidity-sensor.svg";
import Plant from "./plant.svg";
import WaterDrop from "./water-drop.svg";
import WaterDropMobile from "./water-drop-mobile.svg";
import Valve from "./valve.svg";
import ValveMobile from "./valve-mobile.svg";
import Equipment from "./equipment.svg";

const Functioning = {
  BigWaterDrop,
  SmallWaterDrop,
  Extractor,
  Illumination,
  Fan,
  Account,
  HumiditySensor,
  Plant,
  WaterDrop,
  WaterDropMobile,
  Valve,
  ValveMobile,
  Equipment,
};

export default Functioning;
