import * as Yup from "yup";
import i18n from "../../commons/i18n";

export const contactValidation = () =>
  Yup.object().shape({
    name: Yup.string().required(i18n.t("validations.required")),
    email: Yup.string()
      .email(i18n.t("validations.email"))
      .required(i18n.t("validations.required")),
    message: Yup.string().required(i18n.t("validations.required")),
  });

export const acquireEquipmentValidation = Yup.object().shape(
  {
    fullname: Yup.string().required(i18n.t("validations.required")),
    city: Yup.string().required(i18n.t("validations.required")),
    mail: Yup.string()
      .email()
      .when("phone", {
        is: (phone) => !phone || phone.length === 0,
        then: Yup.string()
          .email(i18n.t("validations.email"))
          .required(i18n.t("validations.required")),
        otherwise: Yup.string(),
      }),
    phone: Yup.string().when("mail", {
      is: (email) => email && email.length > 0,
      then: Yup.string(),
      otherwise: Yup.string().required(i18n.t("validations.required"))
      .matches(/^[0-9]*$/, i18n.t("validations.onlyNumbers")),
    }),
  },
  [["mail", "phone"]]
);
