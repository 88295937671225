import styled, { css } from "styled-components";

const StyledElement = styled.div`
  position: absolute;
  display: flex;

  ${(props) =>
    css`
      left: calc(50% + ${props.left}px);
      right: calc(50% + ${props.right}px);
      top: ${props.top}px;
      flex-direction: ${props.direction};
    `}
`;

const PositionWrapper = ({
  topOffset,
  leftOffset,
  rightOffset,
  direction,
  children,
}) => (
  <StyledElement
    left={leftOffset}
    right={rightOffset}
    top={topOffset}
    direction={direction}
  >
    {children}
  </StyledElement>
);

export default PositionWrapper;
