import styled, { css } from "styled-components";
import { Heading2 } from "../../../components/text/Heading";
import DefaultButton from "../../../components/button/DefaultButton";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";

export const MenuButton = styled(DefaultButton)`
  width: 165px;
  height: 48px;
  font-size: 1rem;
`;

export const StHashLink = styled(HashLink)`
  text-decoration: none;
  color: inherit;
`;

export const MenuOption = styled(Heading2)(
  ({ theme: { colors, upTo, downTo } }) => css`
    color: ${colors.neutral00};
    margin: 0.4rem 0;
    font-family: "InterVariable";
    font-weight: 500;
    font-size: 1.5rem;
    &:hover {
      font-weight: 700;
      cursor: pointer;
    }
    &:not(:last-child)::after {
      display: block;
      clear: both;
      content: "";
      position: relative;
      height: 3px;
      width: 2rem;
      border-bottom: 3px solid #fff;
      margin: 0.5rem auto;
      padding: 3px 0px;
      ${upTo("xxl")} {
        top: 1rem;
      }
    }
    ${upTo("xxl")} {
      margin: 1.5rem 0;
    }
    ${downTo("xs")} {
      margin: 0.25rem 0;
    }
  `
);
export const MenuContainer = styled(motion.div)(
  ({ theme: { colors } }) => css`
    display: flex;
    height: calc(100vh - 75px);
    top: 75px;
    width: 100%;
    position: fixed;
    background-color: ${colors.mainPrimary20};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow-x: hidden;
  `
);

export const Content = styled.div(
  ({ theme: { upTo } }) => css`
    position: relative;
    width: 100%;
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
  `
);

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  margin: 1rem;
`;

export const StNav = styled.nav`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

