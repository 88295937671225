import React from "react";
import { useTranslation } from "react-i18next";
import { Heading1 } from "../../../../../../components/text/Heading";
import { FunctioningContainerMobile, FunctioningElementsMobile } from "./styles";
import ElementsMobile from "../../../../../../components/Functioning/elementsMobile";

const MobileFunctioning = () => {
  const { t } = useTranslation("translation");

  return (
    <>
      <FunctioningContainerMobile id="Functioning">
        <Heading1>{t("functioning.title")}</Heading1>
        <FunctioningElementsMobile>
          <ElementsMobile />
        </FunctioningElementsMobile>
      </FunctioningContainerMobile>
    </>
  );
};

export default MobileFunctioning;
