import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Functioning from "../../../../assets/functioning";
import { DescriptionEnd } from "../../description";
import { useTranslation } from "react-i18next";
import { Row } from "../../../Grid";
import Positioning from "../../Positioning";
import { MotionWrapper, elementVariant } from "../../animations";

const Account = () => {
  const { t } = useTranslation("translation");
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden");
  }, [inView, controls]);

  return (
    <Positioning topOffset="1000" rightOffset="310">
      <MotionWrapper
        as={motion.div}
        initial="hidden"
        variants={elementVariant}
        ref={ref}
        animate={controls}
      >
        <Row>
          <DescriptionEnd
            title={t("functioning.account.title")}
            subtitle={t("functioning.account.description")}
          />
          <img src={Functioning.Account} alt="" width="200" />
        </Row>
      </MotionWrapper>
    </Positioning>
  );
};

export default Account;
