import React from "react";
import { useTranslation } from "react-i18next";
import {
  EnterNowContainer,
  GridContainer,
  Image,
  EnterNowHeading,
  EnterNowButtonWrapper,
} from "./styles";
import Button from "../../../../../components/button/DefaultButton";
import { Col, Row } from "../../../../../components/Grid";
import EnterNowImg from "../../../../../assets/enterNow";

const EnterNow = () => {
  const { t } = useTranslation("translation");
  return (
    <EnterNowContainer id="EnterNow">
      <GridContainer>
        <Row>
          <Col>
            <Image src={EnterNowImg.Devices} />
          </Col>
          <Col width="35%" paddingHorizontalDesktop="7.5%">
            <EnterNowHeading>{t("enterNow.heading")}</EnterNowHeading>
            <EnterNowButtonWrapper>
              <Button content={t("enterNow.button")} destination="" />
            </EnterNowButtonWrapper>
          </Col>
        </Row>
      </GridContainer>
    </EnterNowContainer>
  );
};

export default EnterNow;
