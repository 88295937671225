import styled, { css } from "styled-components";

import Backgrounds from "../../assets/backgrounds";
import { Paragraph2 } from "../text/Paragraph";
import { HashLink } from "react-router-hash-link";
import { Col, Row } from "../Grid";

import { ReactComponent as LogoIcon } from "../../assets/header/logo.svg";

export const StyledFooter = styled.footer(
  ({ theme: { downTo } }) => css`
    background-image: url(${Backgrounds.FooterDesktop});
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 300px;

    ${downTo("md")} {
      background-image: url(${Backgrounds.FooterMobile});
      height: 500px;
    }
  `
);

export const FooterContainer = styled.div(
  ({ theme: { downTo } }) => css`
    padding-top: 170px;

    ${downTo("lg")} {
      padding-top: 130px;
    }

    ${downTo("md")} {
      padding-top: 100px;
    }
  `
);

export const FooterRow = styled(Row)`
  align-items: left;
  justify-content: space-between;
`;

export const StyledCol = styled(Col)(
  ({ theme: { downTo } }) => css`
    width: 33%;
    text-align: center;
    height: 50px;

    ${downTo("xl")} {
      height: 55px;
    }

    ${downTo("lg")} {
      width: 50%;
      height: 50px;
    }

    ${downTo("md")} {
      width: 100%;
      height: 40px;
      align-items: flex-start;
    }
  `
);

export const LogoCol = styled(Col)(
  ({ theme: { downTo } }) => css`
    width: auto;

    ${downTo("md")} {
      width: 100%;
      align-items: flex-start;
      padding-bottom: 20px;
    }
  `
);

export const StLogoIcon = styled(LogoIcon)(
  ({ theme: { downTo } }) => css`
    height: 29.5px;

    ${downTo("md")} {
      margin-left: -17.25px;
    }

    :hover {
      cursor: pointer;
    }
  `
);

export const TextCol = styled(Col)(
  ({ theme: { downTo } }) => css`
    width: 700px;

    ${downTo("xxl")} {
      width: 500px;
    }

    ${downTo("xl")} {
      width: 350px;
    }

    ${downTo("lg")} {
      width: 230px;
    }
  `
);

export const SocialsCol = styled(Col)(
  ({ theme: { downTo } }) => css`
    width: auto;

    ${downTo("md")} {
      width: 100%;
      align-items: flex-start;
    }
  `
);

export const Paragraph = styled(Paragraph2)(
  ({ theme: { downTo } }) => css`
    color: white;
    font-size: 14px;

    ${downTo("md")} {
      font-size: 16px;
    }
  `
);

export const StyledSocialNetworks = styled.div(
  ({ theme: { downTo } }) => css`
    display: flex;
    justify-content: space-between;
    width: 150px;

    ${downTo("xl")} {
      transform: scale(0.8);
    }

    ${downTo("lg")} {
      transform: scale(0.6);
    }

    ${downTo("md")} {
      width: 150px;
      padding-left: 15px;
      padding-top: 20px;
      transform: scale(1);
    }
  `
);

export const StHashLink = styled(HashLink)`
  text-decoration: none;
  color: inherit;
  font-family: "InterVariable";
  &:hover {
    font-weight: bolder;
  }
`;

export const SocialNetworkWrapper = styled.a`
  :hover {
    &:nth-child(1) {
      svg {
        fill: #fff;
        path {
          stroke: #94c639 !important;
          fill: #94c639 !important;
          transition: fill 0.25s ease-in-out;
          transition: stroke 0.25s ease-in-out;
          z-index: 999;
        }
      }
    }
    &:nth-child(2) {
      svg {
        fill: #fff;
        path {
          stroke: #94c639 !important;
          transition: stroke 0.25s ease-in-out;
          z-index: 999;
        }
        circle:not(:first-child) {
          stroke: #94c639 !important;
          transition: stroke 0.25s ease-in-out;
          z-index: 999;
        }
      }
    }
    &:nth-child(3) {
      svg {
        fill: #fff;
        path {
          stroke: #94c639 !important;
          fill: #94c639 !important;
          transition: fill 0.25s ease-in-out;
          transition: stroke 0.25s ease-in-out;
          z-index: 999;
        }
      }
    }
  }
`;
